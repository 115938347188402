<template>
  <BackofficeBase :loader_prop="loader_prop">
    <!-- <div class="create_user_div">
      <router-link
        :to="{ path: '/backoffice/add_enduser' }"
        >Create New  User</router-link
      >
    </div> -->
    <div v-for="user in data" :key="user.id" class="item_list">
      <div>
        <p>{{ user.name }}</p>
        <p>{{ user.phone_number }}</p>
        <p>{{ user.email }}</p>
      </div>
      <div class="item_action">
        <router-link :to="{ path: '/backoffice/backoffice_user_packages', query: { phone_number: user.phone_number } }"
          >חבילות</router-link
        >
      </div>
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: "BackofficeEndusers",
  components: {
    BackofficeBase,
  },
  data() {
    return {
      loader_prop: true,
      data: [],
    };
  },
  async mounted() {
    let backendModel = new BackendModel();
    let data = await backendModel.backendRequest(
      "/Api/service/backoffice/backoffice_get_endusers",
      {}
    );
    this.data = data.data.data;
    console.log(this.data);
    this.loader_prop = false;
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
</style>
